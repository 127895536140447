@import "../../scss/config";
@import "../../scss/utilities";

#profile {
  background-color: $light-bg-color;

  .container {
    flex-direction: column;
    text-align: center;

    h1 {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 550px) {
  #profile {
    padding: 2rem 0;
    border-top: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
  }
}
