@import "../../scss/config";
@import "../../scss/utilities";

#event-form {
  .container {
    flex-direction: column;

    h1 {
      text-align: center;
    }

    form {
      margin: auto;
      width: 90%;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem 2rem;

      > div {
        width: 100%;
        &:first-of-type {
          grid-column: 1/3;
        }
      }

      label {
        display: block;
        text-align: start;
        padding-left: 2rem;
        font-size: 1.4rem;
      }
      select,
      input,
      textarea {
        display: block;
        width: 100%;
        padding: 1rem 2rem;
        margin-top: 0.5rem;
        border: 2px solid $secondary-color;
        border-radius: 5px;
        &:focus {
          outline: none;
          border-color: $primary-color;
        }
      }
      .buttons {
        grid-column: 1/3;
        margin: 2rem auto;
      }
    }
  }
}

@media screen and (max-width: 630px) {
  #event-form {
    .container {
      form {
        width: 80%;
        grid-template-columns: 1fr;

        > div {
          &:first-of-type {
            grid-column: 1;
          }
        }

        .buttons {
          grid-column: 1;
        }
      }
    }
  }
}
