@import "../../scss/config";
@import "../../scss/utilities";

#dropzone {
  background-color: $dark-bg-color;
  color: #fff;
  padding: 5rem 3rem;
  min-width: 440px;
  min-height: 150px;
  border-radius: 5px;
  cursor: pointer;
}
