@import "../../scss/config";
@import "../../scss/utilities";

#new-post {
  background-color: $light-bg-color;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      text-align: center;
    }
  }
}
