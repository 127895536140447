@import "../../scss/config";
@import "../../scss/utilities";

/* Navbar */
.navbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $light-text-color;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0px;
  padding: $padding-on-site;
  transition: 0.5s;
  z-index: 4;
  .nav-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: $overlay-bg-color;
    opacity: $navbar-opacity;
    transition-duration: $change-navbar;

    &.top {
      opacity: 0;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 100%;
    margin: 0 auto;
  }

  a {
    color: $light-text-color;
    font-weight: 600;
    padding: 1rem 1.5rem;
    margin: 0 5px;
    background-color: transparent;
    transition-duration: 0s;
  }

  ul {
    display: flex;
  }

  .logo {
    font-weight: 400;
    z-index: 12;

    a {
      padding: 0;
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 4rem;
        margin-right: 1.5rem;
      }

      .nav-title {
        font-size: 2.6rem;
        font-weight: 500;
        transition-duration: $change-navbar;

        &.top {
          display: none;
        }
      }
    }
  }

  /* Preparation for menu-wrap & hamburger */
  .menu-wrap {
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;

    .toggler {
      position: absolute;
      top: 1rem;
      right: 3rem;
      z-index: 12;
      cursor: pointer;
      width: 55px;
      height: 50px;
      opacity: 0;
      display: none;
    }

    .hamburger {
      position: absolute;
      top: 0.9rem;
      right: 3rem;
      z-index: 11;
      width: 60px;
      height: 60px;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
    }

    .menu {
      height: 70px;

      div,
      ul {
        height: 100%;
        align-items: center;
      }

      a {
        &:hover {
          border-bottom: $primary-color 2px solid;
        }
      }
    }
  }
}

/* Header */
.hero {
  background: url("../../assets/header/chur_night.jpg") no-repeat center
    center/cover;
  height: 100vh;
  position: relative;
  color: #fff;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    padding: 0 20px;

    * {
      z-index: 3;
    }

    img {
      max-width: 12rem;
      margin-bottom: 2rem;
    }

    h1 {
      font-size: 6rem;
      font-weight: 600;
    }

    h4 {
      font-family: "Dancing Script", cursive;
      font-size: 2.5rem;
    }

    p {
      font-size: 2.8rem;
      font-weight: 600;
      max-width: 600px;
      margin: 1.5rem 0 2.4rem;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  // All Other Sites
  &.hero-small {
    height: 20vh;
    background-position: 0 35%;

    .content {
      display: none;
    }
  }
}

/* 
############
Media Querys
############ */
@media screen and(max-width: 1245px) {
  .navbar {
    .lg-screen {
      display: none;
    }
  }
}

@media screen and (max-width: 890px) {
  /* Hamburger Line */
  .navbar {
    a {
      font-size: 2rem;
    }
    .lg-screen {
      display: inline;
    }
    .menu-wrap {
      position: fixed;
      top: 0;
      right: 0;

      .hamburger {
        > div {
          position: relative;
          margin-top: 14px;
          flex: none;
          width: 100%;
          height: 4px;
          border-radius: 2px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all $change-navbar ease;

          /* Hamburger Lines - Top & Bottom */
          &::before,
          &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: -12px;
            width: 100%;
            height: 4px;
            border-radius: 2px;
            background: inherit;
          }

          /* Moves Line Down */
          &::after {
            top: 12px;
          }
        }
      }

      /* Toggler Animation */
      .toggler:checked + .hamburger > div {
        transform: rotate(135deg);
      }
      /* Turns Lines Into X */
      .toggler:checked + .hamburger > div:before,
      .toggler:checked + .hamburger > div:after {
        top: 0;
        transform: rotate(90deg);
      }
      /* Rotate On Hover When Checked */
      .toggler:checked:hover + .hamburger > div {
        transform: rotate(225deg);
      }

      /* Show hamburger */
      .toggler,
      .hamburger {
        display: block;
      }

      /* Show Menu */
      .toggler:checked {
        overflow: hidden;
      }

      .toggler:checked ~ .menu {
        visibility: visible;
        > div {
          transform: scale(1);
          transition-duration: $change-navbar;

          > div {
            opacity: 1;
            transition: opacity $change-navbar ease $menu-coming-delay;
          }
        }
      }

      .toggler:checked ~ .hero .content * {
        z-index: 0;
      }

      .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          background: $overlay-bg-color;
          opacity: $overlay-opacity;
          border-radius: 50%;
          width: 300vw;
          height: 300vw;
          display: flex;
          flex: none;
          align-items: center;
          justify-content: center;
          transform: scale(0);
          transition: all $change-navbar ease;
          z-index: 4;

          > div {
            text-align: center;
            max-width: 90vw;
            max-height: 100vh;
            opacity: 0;
            transition: opacity $change-navbar ease;
          }
        }
      }
    }

    ul {
      flex-direction: column;
      justify-content: center;

      li {
        color: $light-text-color;
        font-size: 1.5rem;
        padding: 1.4rem;
      }
    }
  }
}

// TODO: Querformat
