.quote {
  display: flex;

  .flex-quote {
    padding: 3rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 3rem;
    align-items: center;

    .image {
      grid-row: 1/3;
      width: 15rem;
      height: 15rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    p {
      margin: 0;
      grid-column: 2;
      align-self: end;
    }

    .author {
      font-size: 1.4rem;
      align-self: flex-start;
      grid-column: 2;
      grid-row: 2;
    }
  }
}

@media screen and (max-width: 550px) {
  .quote {
    .flex-quote {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      text-align: center;

      .image {
        margin: auto;
        grid-column: 1;
        grid-row: 1;
      }
      p,
      .author {
        grid-column: 1;
        grid-row: 2;
      }
      .author {
        grid-row: 3;
      }
    }
  }
}
