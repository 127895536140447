@import "../../scss/config";
@import "../../scss/utilities";

#events {
  text-align: center;

  .events {
    padding: 3rem;
    .row {
      position: relative;
      flex-wrap: wrap;
    }
  }

  .events-download {
    display: flex;
    padding: 3rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;

    h4 {
      padding-top: 1rem;
    }

    a {
      margin: 3rem;

      div {
        padding: 3rem;
        border-radius: 5px;

        img {
          width: 5rem;
        }
      }
    }
  }
}
