@import "../../scss/config";
@import "../../scss/utilities";

.min-width {
  min-width: 220px;
  margin: 0 2rem 0 0 !important;
}

#post-form {
  margin: 2rem 0;
  width: 100%;

  form {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;

    > div {
      width: 100%;

      &:first-child {
        text-align: center;
      }
    }

    label {
      padding: 0.5rem 1.5rem;
      display: block;
    }
    input,
    textarea {
      width: 100%;
      padding: 1rem 1.5rem;
      width: 100%;
      border: 1px $secondary-color solid;
      border-radius: 5px;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }

    textarea {
      height: 20rem;
    }

    .flex-container {
      display: flex;
      justify-content: center;
    }
  }
}
