@import "../../scss/config";
@import "../../scss/utilities";

#form-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  .image {
    width: 25rem;
    height: 25rem;
    margin: 0 auto;

    img {
      border-radius: 50%;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .flex-item {
    padding: 1rem 2rem;
  }

  form {
    display: grid;
    grid-template-columns: repeat(3, 35rem);
    grid-template-areas:
      "firstname lastname vulgo"
      "address zip city"
      "birthday e1 e1"
      "phone mobile e2"
      "email email2 e3"
      "password password2 e4"
      "school fos degree"
      "entryCU entryStV e5"
      "rank role e6"
      "quote e7 e7"
      "buttons buttons buttons";

    grid-gap: 1rem 2rem;
  }

  // Grid Area
  #firstname-div {
    grid-area: firstname;
  }
  #lastname-div {
    grid-area: lastname;
  }
  #vulgo-div {
    grid-area: vulgo;
  }
  #address-div {
    grid-area: address;
  }
  #zip-div {
    grid-area: zip;
  }
  #city-div {
    grid-area: city;
  }
  #school-div {
    grid-area: school;
  }
  #degree-div {
    grid-area: degree;
  }
  #fos-div {
    grid-area: fos;
  }
  #zip-div {
    grid-area: zip;
  }
  #entryCU-div {
    grid-area: entryCU;
  }
  #entryStV-div {
    grid-area: entryStV;
  }
  #email-div {
    grid-area: email;
  }
  #email2-div {
    grid-area: email2;
  }
  #phone-div {
    grid-area: phone;
  }
  #mobile-div {
    grid-area: mobile;
  }
  #birthday-div {
    grid-area: birthday;
  }
  #password-div {
    grid-area: password;
  }
  #password2-div {
    grid-area: password2;
  }
  #quote-div {
    grid-area: quote;
  }
  #rank-div {
    grid-area: rank;
  }
  #role-div {
    grid-area: role;
  }
  #buttons-div {
    grid-area: buttons;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  label {
    display: block;
    text-align: start;
    padding-left: 2.5rem;
    font-size: 1.4rem;
  }
  select,
  input,
  textarea {
    display: block;
    width: 100%;
    padding: 1rem 2rem;
    margin-top: 0.5rem;
    border: 2px solid $secondary-color;
    border-radius: 5px;

    &:focus {
      outline: none;
      border-color: $primary-color;
    }
  }
}

/* 
############
Media Querys
############ */
@media screen and (max-width: 1150px) {
  #form-field {
    form {
      grid-template-columns: repeat(2, 35rem);
      grid-template-areas:
        "firstname lastname "
        "vulgo birthday"
        "address e1 "
        "zip city"
        "phone mobile "
        "email email2 "
        "password password2 "
        "school fos"
        "degree e2"
        "entryCU entryStV "
        "rank role "
        "quote e7 "
        "buttons buttons ";
    }
  }
}

@media screen and (max-width: 780px) {
  #form-field {
    .image {
      width: 15rem;
      height: 15rem;
    }

    form {
      grid-template-columns: 35rem;
      grid-template-areas:
        "firstname"
        "lastname "
        "vulgo"
        "address"
        "zip"
        "city"
        "birthday"
        "phone"
        "mobile "
        "email"
        "email2 "
        "password"
        "password2 "
        "school"
        "fos"
        "degree"
        "entryCU"
        "entryStV "
        "rank"
        "role "
        "quote "
        "buttons";
    }
  }
}

@media screen and (max-width: 780px) {
  #form-field {
    form {
      width: 80%;
      grid-template-columns: 1fr;
    }
  }
}
