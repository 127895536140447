@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #333;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}

a {
  font-size: 1.6rem;
  color: #333;
  text-decoration: none;
  transition-duration: 0.4s;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
  line-height: 1.2;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.6rem;
}

h3 {
  font-size: 2.2rem;
}

h4 {
  font-size: 2rem;
}

p {
  font-size: 1.6rem;
  margin: 10px 0;
}

img {
  width: 100%;
}

select,
input,
textarea {
  font-size: 1.6rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  &:read-only {
    color: #555;
  }
}
