@import "../../scss/config";
@import "../../scss/utilities";

#login {
  line-height: 1.8;
  border-radius: 5px;

  .column-2 {
    width: 100%;

    form {
      width: 100%;
      max-width: 450px;

      .btn {
        margin: 3rem 0;
      }
    }

    .login-form-group {
      margin-top: 1.5rem;
      width: 100%;

      label {
        display: block;
      }

      input {
        width: 100%;
        padding: 0.5rem;
        border: #ddd 1px solid;
        border-radius: 5px;
      }
    }

    a {
      font-size: 1.4rem;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #login {
    .column-2 {
      align-items: center;
      text-align: center;
    }
  }
}
