.primary-span {
  color: $primary-color;
}
.secondary-span {
  color: $secondary-color;
}

.btn {
  cursor: pointer;
  display: inline-block;
  margin: 2rem;
  padding: 1rem 3rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 5px;
  transition-duration: 0.3s;
  min-height: 45px;
  &:hover {
    opacity: 0.8;
  }
}

.bg-light,
.btn-light {
  color: $dark-text-color !important;
  background-color: $light-bg-color;
}
.bg-dark,
.btn-dark {
  color: $light-text-color;
  background-color: $dark-bg-color;
}
.bg-primary,
.btn-primary {
  color: $light-text-color;
  background-color: $primary-color;
}
.bg-secondary,
.btn-secondary {
  color: $light-text-color;
  background-color: $secondary-color;
}

.btn-outline {
  color: $light-text-color;
  background: transparent;
  border: 1px solid $light-text-color;
}

.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}
.py-4 {
  padding: 4rem 0;
}
.py-5 {
  padding: 5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}
.my-4 {
  margin: 4rem 0;
}
.my-5 {
  margin: 5rem 0;
}

.container {
  width: 100%;
  max-width: $max-width;
  display: flex;
  margin: auto;
}

.icon {
  padding-top: 3rem;
  text-align: center;
}

.flex-items {
  display: flex;
  text-align: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  > div {
    padding: 3rem 8rem;
  }
}

.section-header {
  width: 100%;
  padding: 0 3rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flex-grid {
  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.4rem;
    justify-content: center;
  }
}
.flex-grid .column {
  flex: 22%;

  min-width: 280px;
  max-width: 320px;
  padding: 0.4rem;
  margin: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.flex-grid a {
  transition-duration: 0.4s;

  &:hover {
    opacity: 0.8;
  }
}

.center {
  text-align: center;
  color: #333;
}

@media screen and (max-width: 768px) {
  .flex-grid {
    .column {
      flex: 100%;
      max-width: 50%;
    }

    &.previousEvents {
      display: none;
    }
  }
}

@media screen and (max-width: 550px) {
  .flex-grid {
    .column {
      max-width: 85%;
    }
  }
}
