@import "../../scss/config";
@import "../../scss/utilities";

#team {
  padding-top: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .flex-items {
    margin: 0 auto;
    flex-wrap: wrap;

    > a {
      padding: 3rem 4rem;
    }
  }

  img {
    width: 20rem;
    border-radius: 50%;
  }

  .btn {
    max-width: 18rem;
  }

  a:hover {
    opacity: 0.8;
  }
}
