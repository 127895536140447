@import "../../scss/config";
@import "../../scss/utilities";

#footer {
  position: relative;
  padding: $padding-on-site;
  width: 100vw;

  * {
    margin: 0;
  }
  .container {
    display: grid;
    grid-column-gap: 20px;
    margin: auto;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      padding-top: 3rem;
      font-weight: 400;
    }

    img {
      display: inline-block;
      width: 150px;
    }

    a {
      padding-top: 1.2rem;
      display: block;

      &:hover {
        opacity: 0.8;
      }
    }

    .footer-title {
      grid-row: 1;
    }

    .footer-content {
      grid-row: 2;
    }

    #footer-parter-title {
      grid-column: 2/5;
    }

    #footer-impressum-title,
    #footer-impressum-content,
    #footer-spub {
      justify-self: start;
      text-align: start;
    }

    #footer-fcs {
      justify-self: end;
    }

    #copy-right {
      grid-column: 1/5;
      grid-row: 3;
      padding: 1.5rem;

      p {
        font-size: 1.4rem;
      }
    }
  }
}

@media screen and (max-width: 880px) {
  #footer {
    .container {
      img {
        width: 100px;
      }

      p,
      a {
        font-size: 1.4rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #footer {
    .container {
      grid-template-columns: repeat(3, 1fr);

      #footer-impressum-title,
      #footer-impressum-content,
      #footer-parter-title,
      #copy-right {
        justify-self: center;
        text-align: center;
        grid-column: 1/4;
      }

      #footer-impressum-title {
        grid-row: 1;
        margin-bottom: 1rem;
      }

      #footer-impressum-content {
        grid-row: 2;
      }

      #footer-parter-title {
        grid-row: 3;
      }

      #footer-spub,
      #footer-casutt,
      #footer-fcs {
        grid-row: 4;
      }

      #footer-spub {
        grid-column: 1;
      }
      #footer-casutt {
        grid-column: 2;
      }

      #footer-fcs {
        grid-column: 3;
      }

      #copy-right {
        grid-row: 5;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  #footer {
    .container {
      grid-template-columns: 1fr;

      #footer-impressum-title,
      #footer-impressum-content,
      #footer-parter-title,
      #footer-spub,
      #footer-casutt,
      #footer-fcs,
      #copy-right {
        grid-column: 1;
      }

      #footer-spub,
      #footer-casutt,
      #footer-fcs {
        justify-self: center;
        margin: 1rem;
      }

      #footer-spub {
        grid-row: 4;
      }
      #footer-casutt {
        grid-row: 5;
      }

      #footer-fcs {
        grid-row: 6;
      }

      #copy-right {
        grid-row: 7;
      }
    }
  }
}
