@import "../../scss/config";
@import "../../scss/utilities";

#contact {
  #contact-aktivitas,
  #contact-ahah {
    h1,
    h3,
    p {
      margin: 0.5rem;
    }

    h3 {
      margin-top: 1.2rem;
    }

    p {
      margin-left: 3.7rem;
    }

    a {
      color: white;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  #contact-formular {
    h1 {
      margin-bottom: 3rem;
    }

    form,
    label,
    input,
    textarea {
      width: 100%;
    }

    input,
    textarea {
      margin-bottom: 2rem;
      padding: 1rem;
    }
  }
}
