@import "../../scss/config";
@import "../../scss/utilities";

#members {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .table {
    min-width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  #members {
    .address,
    .mobile {
      display: none;
    }
  }
}
@media screen and (max-width: 810px) {
  #members {
    .city {
      display: none;
    }
  }
}
@media screen and (max-width: 660px) {
  #members {
    .email {
      display: none;
    }
  }
}
@media screen and (max-width: 480px) {
  #members {
    .firstname {
      display: none;
    }
  }
}
