@import "../../scss/config";
@import "../../scss/utilities";

.event-grid {
  .pointer {
    cursor: pointer;
  }

  > div {
    position: relative;

    display: grid;
    grid-template-rows: repeat(2, auto);
    color: $light-text-color;

    &::before {
      z-index: 1;
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000, $alpha: 0.3);
    }

    .event-img {
      position: relative;
      display: inline-block;
      grid-column: 1;
      grid-row: 1/3;
    }

    .event-title {
      z-index: 2;
      align-self: end;
      grid-column: 1;
      grid-row: 2;
    }

    .event-date {
      z-index: 2;
      grid-column: 1;
      grid-row: 1;
      text-align: end;
      p {
        font-weight: 400;
        font-size: 1.8rem;
        margin: rem;
        padding: 1rem;
        display: inline;
      }
    }
  }
}
