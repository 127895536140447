@import "../../scss/config";
@import "../../scss/utilities";

#social-media {
  a {
    &:hover {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 550px) {
  #social-media {
    .flex-items {
      > div {
        padding: 3rem 4rem;
      }
    }
  }
}
