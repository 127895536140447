$primary-color: #7a171b;
$secondary-color: #162b1b;

$dark-bg-color: #333;
$light-bg-color: #f4f4f4;

$overlay-bg-color: #231f20;

$overlay-opacity: 0.9;
$navbar-opacity: 0.75;

$dark-text-color: #333;
$light-text-color: #fff;

$change-navbar: 0.4s;
$menu-coming-delay: 0.4s;

$max-width: 1500px;
$padding-on-site: 0 3rem;
