@import "../../scss/config";
@import "../../scss/utilities";

#topical {
  background-color: $light-bg-color;
}

.flex-columns {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    max-height: 534px;
  }

  .column .column-1,
  .column .column-2 {
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .column-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  p {
    margin: 2rem 0;
  }

  .date,
  .location {
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  .location {
    margin: 0.5rem 0 1rem;
  }

  &.flex-reverse .row {
    flex-direction: row-reverse;
  }

  .container {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .flex-columns {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }
}
